// 2-aug-24 11:32pm

import React, { useState } from 'react';
import axios from 'axios';
import logo from './logo.png';
import './SubscriptionPage.css';

function SubscriptionPage() {
  const [email, setEmail] = useState('');
  const [optionSelected, setOptionSelected] = useState('Option-1');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://subscribe.marriedtothemopnewsletter.online/subscribe', { email, optionSelected });
      //const response = await axios.post('http://localhost:3006/subscribe', { email, optionSelected });
      setMessage(response.data.message);
    } catch (error) {
      console.error('There was an error!', error);
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className="subscription-page-container">
      <img src={logo} alt="Logo" className="logo" />
      <h1>Email Subscription</h1>
      <form onSubmit={handleSubmit} className="subscription-form">
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label>Groups:</label>
          <select
            value={optionSelected}
            onChange={(e) => setOptionSelected(e.target.value)}
            className="form-control"
          >
            <option value="Domestic">Domestic</option>
            <option value="Offices">Offices</option>
            <option value="Commercial">Commercial</option>
            <option value="Builders">Builders</option>
            <option value="Insurance">Insurance</option>
            <option value="EstateAgents">EstateAgents</option>
            <option value="LettingAgents">LettingAgents</option>
            <option value="HousingAssociations">HousingAssociations</option>
            <option value="DomesticCustomers">DomesticCustomers</option>
            <option value="CommercialClients">CommercialClients</option>            

          </select>
        </div>
        <button type="submit" className="btn btn-primary">Subscribe</button>
        {message && <div className="message">{message}</div>}
      </form>
    </div>
  );
}

export default SubscriptionPage;
